/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import { QueryFormData } from '@superset-ui/core';
import { ControlStateMapping } from '@superset-ui/chart-controls';

export function getFormDataFromControls(
  controlsState: ControlStateMapping,
): QueryFormData {
  const formData: Partial<QueryFormData> = {};
  const uniqueGroupByColumns = new Set<string>();
  Object.keys(controlsState).forEach(controlName => {
    const control = controlsState[controlName];
    formData[controlName] = control.value;
    if ('interactive_groupby' in control) {
      // interactive_groupby might be preset in different objects (groupby and all_columns)
      control.interactive_groupby?.forEach((column: string) =>
        uniqueGroupByColumns.add(column),
      );
    }
  });

  if (uniqueGroupByColumns.size) {
    formData.extra_form_data = {
      ...(formData?.extra_form_data ?? {}),
      interactive_groupby: Array.from(uniqueGroupByColumns),
    };
  }

  return formData as QueryFormData;
}
